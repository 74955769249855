import i18n from "@/i18n";
const t = i18n.global.t;

export const LEXIS_NEXIS_KYB_MODELS = [
  "IncludeRegisteredAgents",
  "IncludeDunBradStreet",
  "IncludeUCCFilingsSecureds",
  "IncludeCompanyVerification",
  "IncludeAssociatedBusinesses",
  "IncludeIndustries",
  "IncludeParents",
  "IncludeProperties",
  "IncludeAircrafts",
  "IncludeWatercrafts",
  "IncludeNameVariations",
  "IncludeMotorVehicles",
  "IncludeSourceCounts",
  "IncludeIncorporation",
  "IncludeInternetDomains",
  "IncludeSanctions",
  "IncludeExperianBusinessReports",
  "IncludeProfessionalLicenses",
  "IncludeBusinessRegistrations",
  "IncludeBankruptcies",
  "IncludeOpsSites",
  "IncludeFinances",
  "IncludeUCCFilings",
  "IncludeContacts",
  "IncludeBusinessInsight",
  "IncludeLiensJudgments",
  "IncludeIRS5500",
  "IncludeConnectedBusinesses"
] as const;

export const searchTierOptions = {
  Basic: t("DEALS.LEXIS_NEXIS.EMAIL_SEARCH.SEARCH_TIER.BASIC"),
  Premium: t("DEALS.LEXIS_NEXIS.EMAIL_SEARCH.SEARCH_TIER.PREMIUM")
} as const;

export const searchTypeOptions = {
  EAA: t("DEALS.LEXIS_NEXIS.EMAIL_SEARCH.SEARCH_TYPE.EMAIL_ADDRESS_APPEND"),
  EIA: t("DEALS.LEXIS_NEXIS.EMAIL_SEARCH.SEARCH_TYPE.EMAIL_IDENTITY_APPEND"),
  EIC: t("DEALS.LEXIS_NEXIS.EMAIL_SEARCH.SEARCH_TYPE.EMAIL_IDENTITY_CHECK")
} as const;

export const isMarketingUseOptions = {
  0: t("DEALS.LEXIS_NEXIS.EMAIL_SEARCH.MARKETING_USE.FALSE"),
  1: t("DEALS.LEXIS_NEXIS.EMAIL_SEARCH.MARKETING_USE.TRUE")
} as const;
