<template>
  <service-wrapper
    :title="service"
    :subtitle="$t('DEALS.LEXIS_NEXIS.KYB.SUBTITLE')"
    img="/images/lexisnexis.svg"
    :is-success="currentStatus === COMMERCIAL_DATA_STATUS_SUCCESS"
    :is-error="serviceHasUnexpectedStatus(currentStatus)"
    :json-data="jsonData"
    :provider-name="currentProvider"
    :service-name="service"
    :service-type="$t('DEALS.LEXIS_NEXIS.KYB.SUBTITLE')"
  >
    <div class="flex">
      <div class="flex items-center space-x-2">
        <confirm-service-start
          ref="confirmServiceStartRef"
          :date="currentDate"
          :confirm-handler="selectedTab === REPORT ? startReport : startSearch"
          :status="currentStatus"
          :service-name="service"
          :is-loading="currentLoading"
          :has-json-view="
            Boolean(selectedTab === REPORT ? reportData : searchData)
          "
          :provider-name="currentProvider"
        >
          <template #button>
            <multi-service-run-button
              v-if="selectedTab === REPORT"
              :items="serviceOptions"
              :selected-models="kybReportSelectedOptions"
              @item:selected="handleMultiServiceSelectOption"
            />
            <primary-button
              v-else
              @click.stop="confirmServiceStartRef?.showConfirm?.()"
            >
              {{ $t("COMMON.RUN_SERVICE") }}
            </primary-button>
          </template>
          <template #reload>
            <multi-service-run-button
              v-if="selectedTab === REPORT"
              :items="serviceOptions"
              show-reload
              :selected-models="kybReportSelectedOptions"
              @item:selected="handleMultiServiceSelectOption"
              @menu:closed="kybReportSelectedOptions.splice(0)"
            />
            <lf-link
              v-else
              @click.stop="confirmServiceStartRef?.showConfirm?.()"
            >
              <icon-button :icon="IconReload" />
            </lf-link>
          </template>
          <template v-slot:download>
            <document-download
              v-if="!!lexisNexisData && !isReportLoading && !isSearchLoading"
              :file-types="[$t('COMMON.JSON')]"
              @download="downloadLexisNexisReportDocument"
            />
          </template>
        </confirm-service-start>
      </div>
    </div>

    <template #content>
      <div class="space-y-6">
        <div v-if="currentStatus === COMMERCIAL_DATA_STATUS_NOT_STARTED">
          <div class="w-full">
            {{ $t("DEALS.SERVICES_DESCRIPTION.LEXISNEXIS") }}
          </div>
        </div>
        <service-error
          v-if="serviceHasUnexpectedStatus(currentStatus)"
          :have-response="
            selectedTab === REPORT ? reportNotEmpty : searchNotEmpty
          "
          :service-error-message="currentStatus"
        />
        <business-not-found
          v-if="isNotFound"
          :title="hasReport ? reportStatus : status"
        />
        <div v-else-if="showStatusMessage(status)">
          {{ currentStatus || "-" }}
        </div>

        <template v-if="showData">
          <view-toggle
            :have-search="searchNotEmpty"
            :have-report="reportNotEmpty"
            :selected-tab="selectedTab"
            @tab:click="updateTab"
          />
          <submitted-data
            v-if="
              selectedTab === REPORT
                ? reportStatus === COMMERCIAL_DATA_STATUS_SUCCESS
                : true
            "
            data-cy="submitted-data"
          >
            <div class="flex mb-3 border-b">
              <icon-base
                :icon="IconCompany"
                :width="48"
                :height="48"
                class="mr-4 place-self-center -mt-6"
              />
              <div class="flex flex-col mb-6">
                <lf-h3>{{ submittedLexisNexisData?.CompanyName || "-" }}</lf-h3>
              </div>
            </div>
            <div v-if="selectedTab === SEARCH" class="flex">
              <icon-base icon="address" class="mr-2-5" />
              <div class="text-gray-600">
                {{
                  formatAddress({
                    address_line: `${submittedLexisNexisData?.Address?.StreetAddress1}`,
                    city: submittedLexisNexisData?.Address?.City,
                    state: submittedLexisNexisData?.Address?.State,
                    zip: `${submittedLexisNexisData?.Address?.Zip5}`
                  })
                }}
              </div>
            </div>
            <div v-else>
              <div class="flex flex-wrap gap-4">
                <div
                  v-for="item in reportRequestData"
                  :key="item.label"
                  class="flex space-x-1"
                >
                  <span class="text-label">{{ item.label }}:</span>
                  <span class="text-headline">{{ item.value }}</span>
                </div>
              </div>
            </div>
          </submitted-data>
          <lexis-nexis-search
            v-if="searchData && selectedTab === SEARCH"
            :data="searchData"
          />
          <lexis-nexis-report
            v-else-if="reportData && selectedTab === REPORT"
            :report="reportData"
          />
        </template>
      </div>
    </template>
  </service-wrapper>
</template>

<script setup lang="ts">
import ConfirmServiceStart from "@/views/businessCreditDeals/components/ConfirmServiceStart.vue";
import IconCompany from "@/components/icons/IconCompany.vue";
import IconBase from "@/components/ui/IconBase.vue";

import { computed, ref, type Component } from "vue";
import { useStore } from "vuex";
import { useDeals } from "@/hooks/deals";
import { useI18n } from "vue-i18n";
import { useTabs } from "@/hooks/common";
import isEmpty from "lodash/isEmpty";
import {
  COMMERCIAL_DATA_STATUS_NOT_STARTED,
  COMMERCIAL_DATA_STATUS_SUCCESS,
  PROVIDER_LEXISNEXIS_KYB_SEARCH,
  PROVIDER_LEXISNEXIS_KYB_REPORT,
  NO_BUSINESS_FOUND_MESSAGE,
  SEARCH,
  REPORT
} from "@/helpers/constants";
import {
  serviceHasBeenRun,
  serviceHasUnexpectedStatus,
  showStatusMessage
} from "@/helpers/services";
import {
  getServiceFileName,
  formatAddress,
  camelCaseToWords
} from "@/helpers/formatting";
import { downloadObjectAsJson } from "@/helpers/files";
import { LexisNexisReturnTypes } from "@/enums/dataServices";
import ViewToggle from "@/components/ViewToggle.vue";
import SubmittedData from "@/views/deals/components/shared/SubmittedData.vue";
import LexisNexisReport from "@/components/deals/dataServices/kyb/lexisNexisReport/LexisNexisReport.vue";
import LexisNexisSearch from "@/components/deals/dataServices/kyb/lexisNexisSearch/LexisNexisSearch.vue";
import DocumentDownload from "@/views/deals/components/DocumentDownload.vue";
import BusinessNotFound from "@/views/businessCreditDeals/components/BusinessNotFound.vue";
import ServiceError from "@/components/deals/dataServices/ServiceError.vue";
import MultiServiceRunButton from "@/components/ui/buttons/MultiServiceRunButton.vue";
import PrimaryButton from "@/components/ui/buttons/PrimaryButton.vue";
import type { MultiServiceRunMenuItem } from "@/models/common";
import { LEXIS_NEXIS_KYB_MODELS } from "@/helpers/constants/lexisNexis";
import { useNotification } from "@/hooks/notifications";
import IconButton from "@/components/ui/buttons/IconButton.vue";
import IconReload from "@/components/icons/IconReload.vue";
const service = "LexisNexis";

const { t } = useI18n();

const getModelLocaleKey = (model: string) =>
  model.replace(/([a-z])([A-Z])/g, "$1_$2").toUpperCase();

const serviceOptions: MultiServiceRunMenuItem[] = [
  {
    label: `${t("COMMON.RUN")} ${t("DEALS.TRANSUNION.SELECTED_MODELS")}`,
    model: undefined
  },
  {
    label: `${t("COMMON.RUN")} ${t("COMMON.WITH")} ${t(
      "DEALS.TRANSUNION.ALL_MODELS"
    )}`,
    model: "all"
  },
  ...LEXIS_NEXIS_KYB_MODELS.map((model) => ({
    label: `${t("COMMON.RUN")} ${t(
      `DEALS.LEXIS_NEXIS.KYB.MODELS.${getModelLocaleKey(model)}`
    )}`,
    model
  }))
];

const { showMessage } = useNotification();
const { activeDeal: deal } = useDeals();
const { dispatch, commit } = useStore();
const { selectedTab, updateTab } = useTabs([
  LexisNexisReturnTypes.search,
  LexisNexisReturnTypes.report
]);

const isReportLoading = ref(false);
const isSearchLoading = ref(false);

const kybReportSelectedOptions = ref<string[]>([]);

const confirmServiceStartRef = ref<
  (Component & { showConfirm?: () => void }) | null
>(null);

const hasReportData = computed(
  () =>
    !!deal.value?.commercialData?.lexis_nexis?.kyb_report
      ?.TopBusinessReportResponseEx
);

const isReportRequested = ref(
  serviceHasBeenRun(
    deal.value.commercialData?.statuses?.lexis_nexis?.["kyb_report"]
  ) && hasReportData.value
);

const hasReport = computed(
  () => isReportRequested.value && hasReportData.value
);

const searchNotEmpty = computed(() => !isEmpty(searchData.value));
const reportNotEmpty = computed(() => !isEmpty(reportData.value));

const date = computed(() => {
  return deal.value?.commercialData?.dates?.lexis_nexis_kyb_search || "";
});

const reportDate = computed(() => {
  return deal.value?.commercialData?.dates?.lexis_nexis_kyb_report || "";
});

const status = computed(
  () => deal.value.commercialData?.statuses?.lexis_nexis?.kyb_search || ""
);

const isNotFound = computed(() =>
  hasReport.value
    ? reportStatus.value?.toLowerCase().includes(NO_BUSINESS_FOUND_MESSAGE)
    : status.value.toLowerCase().includes(NO_BUSINESS_FOUND_MESSAGE)
);

const reportStatus = computed(
  () => deal.value.commercialData?.statuses?.lexis_nexis?.kyb_report || ""
);

const jsonData = computed(() => {
  const lexisNexisData = deal.value?.commercialData?.lexis_nexis;
  const dataForCurrentTab =
    selectedTab.value === REPORT
      ? lexisNexisData?.kyb_report
      : lexisNexisData?.kyb_search;
  return dataForCurrentTab || null;
});

const searchData = computed(
  () =>
    deal.value?.commercialData?.lexis_nexis?.kyb_search
      ?.TopBusinessSearchResponseEx?.response || null
);

const reportData = computed(
  () => deal.value?.commercialData?.lexis_nexis?.kyb_report || null
);

const lexisNexisData = computed(() => {
  const searchData =
    deal.value?.commercialData?.lexis_nexis?.kyb_search || null;
  if (!isReportRequested.value) {
    return searchData;
  }

  const reportData =
    deal.value?.commercialData?.lexis_nexis?.kyb_report || null;
  return reportData || searchData;
});

const submittedLexisNexisData = computed(
  () =>
    deal.value?.commercialData?.request_data?.lexis_nexis?.kyb_search
      ?.TopBusinessSearchRequest?.SearchBy || null
);

const showData = computed(
  () =>
    !!Object.values(lexisNexisData.value || {}).length &&
    !isReportLoading.value &&
    !isSearchLoading.value
);

const currentStatus = computed(() =>
  selectedTab.value === REPORT ? reportStatus.value : status.value
);

const reportRequestData = computed(() => {
  const reportData =
    deal.value?.commercialData?.request_data?.lexis_nexis?.kyb_report
      ?.TopBusinessReportRequest;

  if (!reportData) {
    return [];
  }

  const seleIdItem = {
    label: t("DEALS.LEXIS_NEXIS.UCC.SELE_ID"),
    value: reportData.ReportBy?.BusinessIds?.SeleID || "-"
  };

  return Object.entries(reportData.Options).reduce(
    (result, [key, value]) => {
      if (value !== 1) {
        return result;
      }

      result.push({
        label: camelCaseToWords(key),
        value: t("COMMON.YES")
      });

      return result;
    },
    [seleIdItem]
  );
});

const currentDate = computed(() =>
  selectedTab.value === REPORT ? reportDate.value : date.value
);

const currentLoading = computed(() =>
  selectedTab.value === REPORT ? isReportLoading.value : isSearchLoading.value
);

const currentProvider = computed(() =>
  selectedTab.value === REPORT
    ? PROVIDER_LEXISNEXIS_KYB_REPORT
    : PROVIDER_LEXISNEXIS_KYB_SEARCH
);

const handleMultiServiceSelectOption = (item: MultiServiceRunMenuItem) => {
  if (item.model === "all") {
    kybReportSelectedOptions.value.splice(
      0,
      kybReportSelectedOptions.value.length,
      ...LEXIS_NEXIS_KYB_MODELS
    );
    confirmServiceStartRef.value?.showConfirm?.();
    return;
  }

  if (item.model === undefined) {
    if (!kybReportSelectedOptions.value.length) {
      showMessage(t("DEALS.LEXIS_NEXIS.KYB.NO_MODELS_SELECTED"), "warning");
      return;
    }
    confirmServiceStartRef.value?.showConfirm?.();
    return;
  }

  if (kybReportSelectedOptions.value.includes(item.model)) {
    kybReportSelectedOptions.value = kybReportSelectedOptions.value.filter(
      (model) => model !== item.model
    );
    return;
  }

  kybReportSelectedOptions.value.push(item.model);
};

const startSearch = async () => {
  isSearchLoading.value = true;
  try {
    await dispatch("applications/enrichApp", {
      provider: PROVIDER_LEXISNEXIS_KYB_SEARCH
    });
  } catch (e) {
    commit("setGlobalMessage", {
      title: t("COMMON.ERROR_OCCURRED"),
      type: "error"
    });
  } finally {
    isSearchLoading.value = false;
  }
};

const startReport = async () => {
  isReportRequested.value = true;
  isReportLoading.value = true;

  try {
    await dispatch("applications/enrichApp", {
      provider: PROVIDER_LEXISNEXIS_KYB_REPORT,
      options: { lexisNexisKybOptions: kybReportSelectedOptions.value }
    });
    kybReportSelectedOptions.value.splice(0);
  } catch (e) {
    commit("setGlobalMessage", {
      title: t("COMMON.ERROR_OCCURRED"),
      type: "error"
    });
  } finally {
    isReportLoading.value = false;
  }
};

const downloadLexisNexisReportDocument = () => {
  const fileName = getServiceFileName(
    deal.value.partner?.client?.name || "",
    deal.value.business?.business_legal_name || "",
    t("DEALS.LEXIS_NEXIS.KYB.SUBTITLE"),
    "LexisNexis"
  );

  return downloadObjectAsJson(jsonData.value, fileName);
};
</script>
